import React from 'react'
import { Link } from 'gatsby'
import styles from '../components/Wrapper/index.module.scss'
import { HOST_STATUS } from '../data/constants'

export default () => (
  <section className={styles.wrapper}>
    <section className="datacenter">
      <div className="row">
        <div className="col-sm-12">
          <h2>
            <font color="red">Terms Of Service</font>
          </h2>

          <p>
            Thanks for choosing Cboxera.com as your preferred Downloading
            platform. By using our site, you agree to abide by our site usage
            terms and conditions.
          </p>

          <p>These rules are applicable instantly when you</p>

          <p>1) Register as a user at CboxEra.com</p>

          <p>2) Purchase Premium Subscription</p>

          <p>
            These Terms and Conditions are inclusive of CboxEra.com downloading
            policy and operating rules which can be changed from time to time.
            <br />
            Please review the below mentioned points to keep your downloading
            experience smooth.
          </p>
        </div>
      </div>
    </section>
    <section className="datacenter-facilities">
      <div className="row">
        <div className="col-sm-6 col-md-4">
          <div className="facility  wow fadeInRight" data-wow-delay="0.2s">
            <h3>
              <span className="ficon">
                <i className="fa fa-key" />
              </span>
              <span className="title"> Registration</span>
            </h3>

            <p>
              Each account created on Cboxera.com must be used by individual
              person. For reasons of quality and safety, we don&#39;t allows
              users to the exchange or sharing of accounts.
            </p>
          </div>
        </div>

        <div className="col-sm-6 col-md-4">
          <div className="facility   wow fadeInRight" data-wow-delay="0.4s">
            <h3>
              <span className="ficon">
                <i className="fa fa-plug" />
              </span>
              <span className="title"> Responsibility</span>
            </h3>
            <p>
              Neither we nor our hosting or Dedicated servers are responsible
              for your use of our service. Download illegal files is strictly
              forbidden.
            </p>
          </div>
        </div>

        <div className="col-sm-6 col-md-4">
          <div className="facility   wow fadeInRight" data-wow-delay="0.6s">
            <h3>
              <span className="ficon">
                <i className="fa fa-leaf" />
              </span>
              <span className="title"> No Refund policy</span>
            </h3>
            <p>
              Once the subscription is activated we will not provide any kind of
              Refund, so please select your subscription accordingly.{' '}
              <span className="text-danger">
                So please don't for a particular host because we can't gurantee
                uptime of that host.
              </span>
            </p>
          </div>
        </div>

        <div className="col-sm-6 col-md-4">
          <div className="facility   wow fadeInLeft" data-wow-delay="0.8s">
            <h3>
              <span className="ficon">
                <i className="fa fa-fighter-jet" />
              </span>
              <span className="title"> Fair Usage & Limits Policy</span>
            </h3>

            <p>
              Please check{' '}
              <Link className="text-danger" to={HOST_STATUS}>
                host status page{' '}
              </Link>{' '}
              for filesize and bandwidth limits. Please note that allowed
              filesize/bandwidth limits can change (increase/decrease) at any
              time without any notice.
            </p>
          </div>
        </div>

        <div className="col-sm-6 col-md-4">
          <div className="facility  wow fadeInLeft" data-wow-delay="1s">
            <h3>
              <span className="ficon">
                <i className="fas fa-shield-alt" />
              </span>
              <span className="title"> Password Safety</span>
            </h3>

            <p>
              All users must use strong passwords for there accounts. If any
              case your password is misused for leech we are not responsible.
              You can anytime change your password from your profile options.
            </p>
          </div>
        </div>

        <div className="col-sm-6 col-md-4">
          <div className="facility   wow fadeInLeft" data-wow-delay="1.2s">
            <h3>
              <span className="ficon">
                <i className="fa fa-lock" />
              </span>
              <span className="title"> Security</span>
            </h3>
            <p>
              If we find any user sharing account or leeching with cboxera
              account. We have all rights to permanently block the account
              without notice.
            </p>
          </div>
        </div>
      </div>
    </section>
  </section>
)
